import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { memoize } from "proxy-memoize";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, CircularProgress, Button, Tooltip, IconButton } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { min, max } from "lodash";
import { formatDateTime, parseDateTime } from "../helpers/time";
import history from "../helpers/history";
import { Transmitter } from "../helpers/glossary";

import { useSelectorTyped as useSelector, useThunkDispatch } from "../redux/common";
import {
  queryDetectionSummary,
  queryDetections,
  cancelCurrentQuery,
  fetchDetectionCountsHistogram,
  queryAbacus,
  clearDetectionData,
  getDetCountApi,
  resetTable,
  startDownloadDetections,
} from "../redux/detections/detection-actions";
import { DetectionCountOptions, DetectionFilterInput } from "../redux/detections/detection-types";
import { listFilesApi } from "../redux/files/files-actions";
import { FileInfo } from "../redux/files/files-types";
import { readDeploymentList } from "../redux/deployments/deployments-actions";

import { SpinnerInnovasea, ListItemSwitch } from "../fathom-brella";
import ResizableSplitPanel from "../components/common/ResizableSplitPanel";
import DateTimePicker from "../components/common/DateTimePicker";
import FlexCol from "../components/common/FlexCol";
import FlexRow from "../components/common/FlexRow";
import useFileProcessingWarning from "../components/hooks/useFileProcessingWarning";
import HelpPopover from "../components/common/HelpPopover";
import { SubTitle, Title } from "../components/common/typography";
import { AutocompleteChips, AutoCompleteOptions } from "../components/common/inputs";
import { DetectionVisualizer } from "../components/detection/DetectionVisualizer";
import { AbacusPlot, isFeatureEnabled } from "../components/common/ExperimentalFeaturesDialog";
import { readAnimalList } from "../redux/animals/animals-actions";
import WarningIcon from "../components/common/WarningIcon";
import {
  BIN_WIDTHS,
  DEFAULT_BIN_SECONDS,
  IDEAL_BINS,
  TEMP_TABLE_ROW_LIMIT,
} from "../components/detection/detection-consts";
import { Duration } from "../components/detection/detection-helper";
import {
  RxSelection,
  TxSelection,
  SelectionInterval,
  TimeRangeTs,
  AbacusGroupMode,
  RxList,
  DevicesInScope,
} from "../components/detection/detection-types";
import { getLatestEventTimeOfType, AnimalEventType } from "../helpers/animals";
import { useStore } from "react-redux";
import { RootState } from "../redux/store";

/** Time range as ISOString */
type TimeRange = {
  min: string;
  max: string;
};

/** reference time for calculating min date. Doesn't matter that it's not exactly "now" */
const nowIsh = new Date().toISOString();

export function getBinInfo(nSeconds: number, idealBins: number = IDEAL_BINS): Duration {
  const secondsPerBin = nSeconds / idealBins;

  const differences = BIN_WIDTHS.map(binWidth => ({
    binWidth,
    difference: Math.abs(binWidth.getValueSeconds() - secondsPerBin),
  }));

  return differences.reduce((accumulator, currentValue) =>
    currentValue.difference < accumulator.difference ? currentValue : accumulator
  ).binWidth;
}

//#region MEMO SELECT STATE
const selectAnimalState = memoize((state: RootState) => {
  const { animals, loading: animalLoading, error: animalError } = state.animals;

  const animalsByTxID = new Map<string, any>(); // map of txid > animals
  animals?.forEach(animal => {
    animal.devices.forEach(device =>
      device.transmitters.forEach(tx => {
        animalsByTxID.has(tx.displayId)
          ? animalsByTxID.set(tx.displayId, [...animalsByTxID.get(tx.displayId), animal])
          : animalsByTxID.set(tx.displayId, [animal]);
      })
    );
  });
  return { animalLoading, animalError, animalsByTxID };
});

const selectContext = memoize((state: RootState) => {
  const selectedWorkspaceId = (state.workspaces.selectedWorkspace || ({} as any)).id;
  const selectedStudyId = state.study.selectedId;
  return {
    selectedStudyId,
    selectedWorkspaceId,
  };
});

const selectStudyState = memoize((state: RootState) => {
  const selectedStudyId = state.study.selectedId;
  const studies = state.study.studies;
  const selectedStudy =
    (selectedStudyId && studies?.find((s: any) => s.id === selectedStudyId)) || {};
  const studyFileNames: string[] = selectedStudy?.files?.map(file => file.name) || [];
  const studyDeploymentIds: string[] = selectedStudy?.deployments?.map(({ id }) => id) || [];
  const studyAnimalsTxIDs: string[] = [];
  selectedStudy.animals?.forEach(animal => {
    animal.devices.forEach(device => {
      device.transmitters.forEach(tx => {
        studyAnimalsTxIDs.push(tx.displayId);
      });
    });
  });

  return {
    selectedStudyId,
    selectedStudy,
    studyFileNames,
    studyDeploymentIds,
    studyAnimalsTxIDs,
  };
});

const selectDeploymentsState = memoize((state: RootState) => {
  const deployments = state.deployments.deployments;

  const { selectedStudyId, studyDeploymentIds } = selectStudyState(state);

  // find time range of deployments:
  const rxTimeRangeMap = {} as { [serial: string]: TimeRange };
  const deploymentsRxs: string[] = [];
  deployments?.forEach(deployment => {
    if (!selectedStudyId || studyDeploymentIds.includes(deployment.id)) {
      deployment?.deviceAttachments?.forEach(da => {
        if (da.device?.capabilities?.includes("RECEIVER")) {
          const serial = da.device.serial;
          const min = da.start || deployment.start;
          const max = da.end || deployment.end;

          // get min / max deployment time per rx
          if (!rxTimeRangeMap[serial]) {
            rxTimeRangeMap[serial] = { min: nowIsh, max: "" };
          }
          // there may be more than one deployment of a given receiver, so pick the earliest start and latest end
          rxTimeRangeMap[serial].min =
            rxTimeRangeMap[serial].min < min ? rxTimeRangeMap[serial].min : min;
          rxTimeRangeMap[serial].max =
            rxTimeRangeMap[serial].max > max ? rxTimeRangeMap[serial].max : max;

          // also add to list of rx in deployments:
          deploymentsRxs.push(serial);
        }
      });
    }
  });

  // reduce min / max deployment time per rx to only one min/max
  const deploymentsTimeRange: TimeRange = { min: nowIsh, max: "" };
  for (const serial in rxTimeRangeMap) {
    deploymentsTimeRange.min =
      deploymentsTimeRange.min < rxTimeRangeMap[serial].min
        ? deploymentsTimeRange.min
        : rxTimeRangeMap[serial].min;
    deploymentsTimeRange.max =
      deploymentsTimeRange.max > rxTimeRangeMap[serial].max
        ? deploymentsTimeRange.max
        : rxTimeRangeMap[serial].max;
  }

  return {
    deploymentsTimeRange,
    rxTimeRangeMap,
    deploymentsRxs,
  };
});

const selectFilesState = memoize((state: RootState) => {
  const filesLoaded = state.files.isLoaded;
  const files = state.files.fileList;

  // get relevant study state:
  const { selectedStudyId, studyFileNames } = selectStudyState(state);
  // get relevant deployments state:
  const { deploymentsRxs } = selectDeploymentsState(state);

  const fileList: FileInfo[] = [];
  const deploymentsFiles = files
    .filter(f => deploymentsRxs.includes(f.rxLogProperties?.serial || ""))
    .map(f => f.name);

  for (const file of files) {
    if (
      !selectedStudyId ||
      studyFileNames.includes(file.name) ||
      deploymentsFiles.includes(file.name)
    ) {
      fileList.push(file);
    }
  }
  const filesRxs = fileList.map(file => file.rxLogProperties?.serial);
  const filesHasHR = fileList.some(file => file.rxLogProperties?.counts.hrExternalAccepted);
  // const filesTimeRange = minMaxTimesFilelist(fileList);
  const filesTimeRange: TimeRange = {
    min: nowIsh,
    max: "",
  };
  fileList.forEach(({ rxLogProperties }) => {
    if (rxLogProperties) {
      if (rxLogProperties.maxRecordTime > filesTimeRange.max) {
        filesTimeRange.max = rxLogProperties.maxRecordTime;
      }
      /**
       * If we include detections with epoch = 0 (1970-01-01) query performance
       * is way worse because it's creating hourly bins from the epoch 0 to the actual
       * start time of the data. Often creating 40+ years of empty bins
       */
      if (
        rxLogProperties.minRecordTime < filesTimeRange.min &&
        rxLogProperties.minRecordTime > "1970-01-01T00:00:00Z"
      ) {
        filesTimeRange.min = rxLogProperties.minRecordTime;
      }
    }
  });

  return {
    filesLoaded,
    fileList,
    filesRxs,
    filesTimeRange,
    filesHasHR,
  };
});

const selectDetectionSummary = memoize((state: RootState) => {
  const detection = state.detection;
  const summaryLoading = detection.summary.loading;
  const summaryError = detection.summary.error;
  const detectionSummary = detection.summary.detectionSummary;

  // get relevant study state:
  const { filesRxs, fileList } = selectFilesState(state);

  // get relevant deployments state:
  const { deploymentsRxs } = selectDeploymentsState(state);

  ///////////////////////////////////////
  /** Receiver objects, filtered by study, files, deployments */
  const rxs: RxList = [];

  if (!summaryLoading && !summaryError && detectionSummary) {
    detectionSummary.receiverSummary.forEach(rs => {
      const serial = rs.receiver.serial;
      if (filesRxs.includes(serial) || deploymentsRxs.includes(serial)) {
        const model = fileList.find(fi => fi.rxLogProperties?.serial === serial)?.rxLogProperties
          ?.model;
        rxs.push({
          count: rs.count,
          rxName: model ? `${model.split("-")[0]}-${serial}` : serial,
          serial,
          idCounts: rs.idCounts,
        });
      }
    });

    //First, we check which receivers were included by searching detections table.
    //Then, we check which receivers were left out for not having any detections
    const rxsFromDetections = rxs.map(rx => rx.serial);
    //filters out undefined elements, changing the type to just string[]
    //ensuring only files with serials are considered
    const noDetectionSerials = filesRxs.flatMap(rx =>
      rx && !rxsFromDetections.includes(rx) ? [rx] : []
    );
    noDetectionSerials.forEach(serial => {
      const model = fileList.find(fi => fi.rxLogProperties?.serial === serial)?.rxLogProperties
        ?.model;
      rxs.push({
        count: 0,
        rxName: model ? `${model.split("-")[0]}-${serial}` : serial,
        serial,
        idCounts: [],
      });
    });
  }
  const totalCount = rxs.map(r => r.count).reduce((acc, val) => (acc += val), 0);

  return {
    summaryLoading,
    summaryError,
    rxs,
    totalCount,
    count: detection.count,
  };
});
//#endregion

const useStyles = makeStyles(() => ({
  listItemText: {
    fontSize: 12,
  },
}));

let debounceFetch: NodeJS.Timeout;

function Detections() {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const storeState = useStore().getState();

  // Feature flag
  const abacusEnabled = isFeatureEnabled(AbacusPlot);

  //
  //#region COMPONENT STATE
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  // Keep the initial values for clear filters btn
  const [initialStart, setInitialStart] = useState("");
  const [initialEnd, setInitialEnd] = useState("");

  const [displayMode, setDisplayMode] = useState<"table" | "abacus">("abacus");
  const [selectedRxs, setSelectedRxs] = useState([] as RxSelection[]);
  const [selectedTxs, setSelectedTxs] = useState([] as TxSelection[]);
  const [currentFilters, setCurrentFilters] = useState({} as DetectionFilterInput);
  const [devicesInScope, setDevicesInScope] = useState<DevicesInScope>({
    receiverSerials: [],
    transmitterIDs: [],
  });
  const [filtersChanged, setFiltersChanged] = useState<boolean>(true);
  const [filterInterval, setFilterInterval] = useState<TimeRangeTs>({ min: 0, max: 0 });
  const [overviewBinSeconds, setOverviewBinSeconds] = useState(DEFAULT_BIN_SECONDS);
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectionInterval, setSelectionInterval] = useState([] as SelectionInterval); // for DetectionOverview to set selected interval for filtering results
  const [dataInterval, setDataInterval] = useState({ min: 0, max: 0 } as TimeRangeTs); // for keeping track of the min/max detection time
  const [abacusGroupBy, setAbacusGroupBy] = useState("RX" as AbacusGroupMode); // grouping by rx or tx
  const dataIntervalRef = useRef({ min: 0, max: 0 } as TimeRangeTs); // to know when the min/max detection time changes
  const [studyAnimalsOnly, setStudyAnimalsOnly] = useState<boolean>(false);
  //#endregion
  //

  //
  //#region STORE STATE
  const { selectedStudyId, selectedWorkspaceId } = selectContext(storeState);

  const { studyAnimalsTxIDs } = selectStudyState(storeState);

  const { deploymentsTimeRange } = selectDeploymentsState(storeState);

  const { animalLoading, animalError, animalsByTxID } = selectAnimalState(storeState);

  const { filesLoaded, filesHasHR, filesTimeRange } = selectFilesState(storeState);

  const { summaryLoading, summaryError, rxs, totalCount, count } =
    selectDetectionSummary(storeState);

  const { detectionRows, detectionState, detectionHistogram, detectionAbacus } = useSelector(
    ({ detection }) => {
      // Set the dataInterval (min/max detection time) if it has changed:
      const histLength = detection.histogram?.countPerInterval?.length || 0;
      const _dataInterval: TimeRangeTs = {
        min: (histLength && detection.histogram.countPerInterval[0].dt) || 0,
        max: (histLength && detection.histogram.countPerInterval[histLength - 1].dt) || 0,
      };
      if (
        _dataInterval.min != dataIntervalRef.current.min ||
        _dataInterval.max != dataIntervalRef.current.max
      ) {
        dataIntervalRef.current = _dataInterval;
        setDataInterval(_dataInterval);
      }

      return {
        detectionRows: detection.table.rows,
        detectionState: detection.table.status,
        detectionHistogram: detection.histogram,
        detectionAbacus: detection.abacus,
      };
    }
  );
  //
  //#endregion
  //

  const detCount = initialLoad ? totalCount : count.count || 0;
  const tableLimitReached = detCount > TEMP_TABLE_ROW_LIMIT; // 1 Mill
  const zeroDets = detCount === 0;
  const dataSourceReady = filesLoaded && !summaryLoading && !animalLoading;
  const dataSourceError = summaryError || animalError;

  const txs = useMemo(() => {
    const selRxSerials = selectedRxs.length ? selectedRxs.map(sr => sr.serial) : [];
    const rxTemp: RxList = rxs.filter(r =>
      selRxSerials.length ? selRxSerials.includes(r.serial) : true
    );
    const txIdCounts: { [displayId: string]: number } = {};
    const txSerials: { [displayId: string]: string[] } = {};
    rxTemp.forEach(r => {
      r.idCounts.forEach(({ displayId, count }) => {
        if (txIdCounts[displayId] === undefined) {
          txIdCounts[displayId] = 0;
        }
        if (txSerials[displayId] === undefined) {
          txSerials[displayId] = [];
        }
        txSerials[displayId].push(r.rxName);
        txIdCounts[displayId] += count;
      });
    });
    const selectableTxs: TxSelection[] = Object.keys(txIdCounts).map(displayId => ({
      displayId,
      serials: txSerials[displayId],
      count: txIdCounts[displayId],
    }));
    return selectableTxs.sort((t1, t2) => t2.count - t1.count);
  }, [rxs, selectedRxs]);

  const filters = useMemo<DetectionFilterInput>(() => {
    return {
      includeReceiverSerials:
        (selectedRxs.length > 0 && selectedRxs?.map(({ serial }) => serial)) ||
        rxs.map(rx => rx.serial),
      includeTransmitterIDs:
        (selectedTxs.length > 0 && selectedTxs?.map(({ displayId }) => displayId)) || undefined,
      includeStartTime: (start && parseDateTime(start)) || undefined,
      includeEndTime: (end && parseDateTime(end)) || undefined,
    };
  }, [start, end, rxs, selectedRxs, selectedTxs]);

  function resetState() {
    setStart("");
    setEnd("");
    setSelectedRxs([]);
    setSelectedTxs([]);
    setInitialLoad(true);
  }

  const applyFilters = useCallback(() => {
    // clear the histogram selection since the new time range may be completely different:
    setSelectionInterval([]);

    // set "effective filters" to explicitly include all the available options if none are selected
    const effectiveFilters = {
      includeReceiverSerials: filters.includeReceiverSerials?.length
        ? filters.includeReceiverSerials
        : rxs.map(({ serial }) => serial),
      includeTransmitterIDs:
        (filters.includeTransmitterIDs?.length && filters.includeTransmitterIDs) || undefined,
      includeEndTime: filters.includeEndTime,
      includeStartTime: filters.includeStartTime,
    } as DetectionFilterInput;

    setCurrentFilters(effectiveFilters);
    // list of selected (or all if none selected) receiver serials and transmitter ids for the domain of the DetectionChart
    const _devicesInScope = {
      receiverSerials: filters.includeReceiverSerials?.length
        ? filters.includeReceiverSerials
        : rxs.map(({ serial }) => serial),
      transmitterIDs: filters.includeTransmitterIDs?.length
        ? filters.includeTransmitterIDs
        : txs.map(({ displayId }) => displayId),
    };
    setDevicesInScope(_devicesInScope);
    const _filterInterval: TimeRangeTs = {
      min: filters.includeStartTime?.valueOf() || new Date(filesTimeRange.min).valueOf() || 0,
      max: filters.includeEndTime?.valueOf() || new Date(filesTimeRange.max).valueOf() || 0,
    };
    setFilterInterval(_filterInterval);

    const adjustedBinSeconds =
      _filterInterval.max && _filterInterval.min
        ? getBinInfo(
          (_filterInterval.max - _filterInterval.min) / 1000,
          IDEAL_BINS
        ).getValueSeconds()
        : DEFAULT_BIN_SECONDS;
    setOverviewBinSeconds(adjustedBinSeconds);
    dispatch(cancelCurrentQuery());
    dispatch(fetchDetectionCountsHistogram(effectiveFilters, adjustedBinSeconds));
    dispatch(getDetCountApi(effectiveFilters));
    dispatch(queryDetections(filters, rxs));
    setFiltersChanged(false);
    setInitialLoad(false);
  }, [dispatch, filesTimeRange, filters, rxs, txs]);

  function downloadDetections() {
    dispatch(startDownloadDetections(filters, detCount));
  }
  //
  //#region EFFECTS
  //
  // changing workspace || study and initital load
  useEffect(() => {
    resetState();
    dispatch(clearDetectionData());
    dispatch(queryDetectionSummary());
    dispatch(listFilesApi());
    dispatch(readDeploymentList());
    dispatch(readAnimalList());
    dispatch(cancelCurrentQuery());
    setStudyAnimalsOnly(false);
  }, [dispatch, selectedWorkspaceId, selectedStudyId]);

  // Set initial time range:
  useEffect(() => {
    const _start = min([filesTimeRange.min, deploymentsTimeRange.min]) as string;
    const _end = max([filesTimeRange.max, deploymentsTimeRange.max]) as string;

    setInitialStart(formatDateTime(_start));
    setInitialEnd(formatDateTime(_end));

    if (_start != nowIsh) {
      setStart(formatDateTime(_start));
    }
    if (_end != "") {
      setEnd(formatDateTime(_end));
    }
  }, [filesTimeRange, deploymentsTimeRange]);

  // Fetching Abacus data:
  useEffect(() => {
    if (displayMode === "abacus" && filterInterval?.min > 0 && filterInterval?.max > 0) {
      // start based on selection interval, constrained by the time extents of the time filter:
      const start = max([selectionInterval?.[0], filterInterval.min || 0]) as number; // TS didn't realize this would always resolve to a number
      const end = min([selectionInterval?.[1], filterInterval.max || 0]) as number;
      const timeDifference = end - start;
      if (timeDifference > 0) {
        const abacusFilters: DetectionFilterInput = {
          includeReceiverSerials: currentFilters.includeReceiverSerials,
          includeTransmitterIDs: currentFilters.includeTransmitterIDs,
          includeStartTime: new Date(start),
          includeEndTime: new Date(end),
        };
        const binWidth = getBinInfo(timeDifference / 1000);
        const abacusOptions: DetectionCountOptions = {
          byReceiverSerial: abacusGroupBy === "RX",
          byTransmitterIDs: abacusGroupBy === "TX",
          binIntervalSeconds: binWidth.getValueSeconds(),
        };
        clearTimeout(debounceFetch);
        debounceFetch = setTimeout(() => {
          dispatch(queryAbacus(abacusFilters, abacusOptions));
        }, 250);
      }
    }
  }, [dispatch, displayMode, currentFilters, filterInterval, abacusGroupBy, selectionInterval]);

  // load data on initial render (or reset etc):
  useEffect(() => {
    if (initialLoad && dataSourceReady && !dataSourceError) {
      applyFilters();
    }
  }, [initialLoad, dataSourceReady, dataSourceError, applyFilters]);
  //
  //#endregion
  //

  useFileProcessingWarning();

  // escape hatches:
  if (!dataSourceReady) {
    return <SpinnerInnovasea text={"Preparing detection database"} />;
  }

  if (dataSourceError) {
    history.push("/error");
    return null;
  }

  function filterChange(field?: "start" | "end" | "selectedRxs" | "selectedTxs", value?: any) {
    if (field === "start") {
      setStart(value);
      filters.includeStartTime = parseDateTime(value);
    } else if (field === "end") {
      setEnd(value);
      filters.includeEndTime = parseDateTime(value);
    } else if (field === "selectedRxs") {
      const newRxs = rxs.filter(rx => value.includes(rx.serial));
      setSelectedRxs(newRxs);
      filters.includeReceiverSerials = newRxs.map(r => r.serial);
    } else if (field === "selectedTxs") {
      const newTxs = txs.filter(tx => value.includes(tx.displayId));
      setSelectedTxs(newTxs);
      const selectedTxIds = newTxs.map(t => t.displayId);
      filters.includeTransmitterIDs = selectedTxIds;
      // keep studyAnimalsOnly in sync
      const onlyStudyAnimalsSelected =
        newTxs.length === studyAnimalsTxIDs.length &&
        studyAnimalsTxIDs.filter(sid => selectedTxIds.includes(sid)).length ==
        studyAnimalsTxIDs.length;
      setStudyAnimalsOnly(onlyStudyAnimalsSelected);
    }

    dispatch(resetTable());
    dispatch(getDetCountApi(filters));
    setInitialLoad(false);
    setFiltersChanged(true);
  }

  function haveFiltersSelected() {
    if (selectedRxs.length > 0) {
      return true;
    }
    if (selectedTxs.length > 0) {
      return true;
    }
    if (start != initialStart) {
      return true;
    }
    if (end != initialEnd) {
      return true;
    }
    return false;
  }

  function clearFilters() {
    filterChange("selectedRxs", []);
    filterChange("selectedTxs", []);
    setStart(initialStart);
    setEnd(initialEnd);
  }

  const rxOptions: AutoCompleteOptions[] = rxs.map(rx => ({
    key: rx.serial,
    label: rx.rxName,
    labelSuffix: (
      <Typography variant="caption" style={{ padding: 5 }}>
        (DETS: {rx.count})
      </Typography>
    ),
  }));

  function transmitterLabel(transmitterID: string): string {
    const txIDAnimals: any[] = animalsByTxID.get(transmitterID);

    if (txIDAnimals) {
      txIDAnimals.sort((animal1, animal2) => {
        return (
          (getLatestEventTimeOfType(animal2.events, AnimalEventType.TAGGING) || 0) -
          (getLatestEventTimeOfType(animal1.events, AnimalEventType.TAGGING) || 0)
        );
      });
      return txIDAnimals[0].name ? `${txIDAnimals[0].name} (${transmitterID})` : transmitterID;
    }

    return transmitterID;
  }

  const txOptions: AutoCompleteOptions[] = txs.map(tx => ({
    key: tx.displayId,
    label: transmitterLabel(tx.displayId),
    labelSuffix: (
      <Typography variant="caption" style={{ padding: 5 }}>
        (DETS: {tx.count})
      </Typography>
    ),
  }));

  return (
    <ResizableSplitPanel
      direction="horizontal"
      firstInit="30%"
      firstMin={300}
      firstContent={
        <FlexCol fullWidth fullHeight paddingLevel={2} autoScrollY>
          <FlexRow>
            <Title style={{ paddingRight: 24 }}>Filters</Title>
            {haveFiltersSelected() && (
              <Button variant="outlined" style={{ padding: "0px 18px" }} onClick={clearFilters}>
                Clear All
              </Button>
            )}
          </FlexRow>

          <FlexCol paddingLevel={1}>
            <SubTitle>Devices</SubTitle>
            <AutocompleteChips
              options={rxOptions}
              limitTags={2}
              selection={selectedRxs.map(({ rxName, serial }) => ({
                key: serial,
                label: rxName,
              }))}
              selectionHandler={selection =>
                filterChange(
                  "selectedRxs",
                  selection.map(s => s.key)
                )
              }
              label={`Search for receivers in ${(selectedStudyId && "study") || "workspace"}`}
              placeholder="Type to filter and/or select from below"
            />
            <AutocompleteChips
              options={txOptions}
              limitTags={2}
              selection={selectedTxs.map(({ displayId }) => ({
                key: displayId,
                label: transmitterLabel(displayId),
              }))}
              selectionHandler={selection =>
                filterChange(
                  "selectedTxs",
                  selection.map(s => s.key)
                )
              }
              label={`Search for ${Transmitter.pluralText}`}
              placeholder="Type to filter and/or select from below"
            />
            <FlexRow vAlign="center">
              <ListItemSwitch
                text="Only Study Animals"
                checked={studyAnimalsOnly}
                tooltip={{
                  text: selectedStudyId
                    ? studyAnimalsTxIDs.length == 0
                      ? "There are no animals tagged in the currently selected study."
                      : "Automatically select all animals in the currently selected study."
                    : `Select a study to limit ${Transmitter.pluralText} to animals in that study.`,
                  placement: "bottom",
                }}
                onChange={() =>
                  studyAnimalsOnly
                    ? filterChange("selectedTxs", [])
                    : filterChange("selectedTxs", studyAnimalsTxIDs)
                }
                disabled={!selectedStudyId || studyAnimalsTxIDs.length == 0}
                size="small"
                listItemTextStyle={classes.listItemText}
              />
              {filesHasHR && (
                <FlexRow vAlign="center" hAlign="right">
                  <Typography variant="caption" noWrap={true}>
                    HR Detections
                  </Typography>
                  <HelpPopover
                    tooltip="Where are my HR detections?"
                    helpContent={
                      "Support for working with detection data from HR2 and HR3 receivers is under development"
                    }
                  />
                </FlexRow>
              )}
            </FlexRow>
          </FlexCol>
          <FlexCol paddingLevel={1}>
            <SubTitle>Time Frame</SubTitle>
            <FlexCol itemSpacing={1.5} paddingLevel={1.5} style={{ paddingTop: 0 }}>
              <DateTimePicker
                label="Start Time"
                value={start}
                onChange={(value: string) => filterChange("start", value)}
                helperText={"Limit to detections after this time"}
              />
              <DateTimePicker
                label="End Time"
                value={end}
                onChange={(value: string) => filterChange("end", value)}
                helperText={"Limit to detections before this time"}
              />
            </FlexCol>
          </FlexCol>
          <FlexCol hAlign="center" paddingLevel={2} itemSpacing={2}>
            <FlexRow spaceBetween itemSpacing={1}>
              {filtersChanged && (
                <>
                  <Tooltip
                    title={
                      tableLimitReached && displayMode === "table"
                        ? `Note: only the first ${TEMP_TABLE_ROW_LIMIT} rows will be displayed`
                        : zeroDets
                          ? "No detections found"
                          : ""
                    }
                    placement="top"
                  >
                    <span>
                      <Button
                        onClick={applyFilters}
                        variant="outlined"
                        disabled={count.loading || zeroDets}
                      >
                        Apply Filters
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title="By default, all data is selected prior to any filtering."
                    placement="top"
                  >
                    <IconButton size="small">
                      <Help />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {zeroDets && !count.loading && (
                <WarningIcon
                  tooltip={`This tool is driven by study associations. Please ensure you have linked files and/or deployments to the study you wish to analyze.`}
                />
              )}
            </FlexRow>

            {count.loading || summaryLoading ? (
              <CircularProgress size={18} />
            ) : (
              <SubTitle style={{ margin: 8 }}>
                {` ${initialLoad ? totalCount.toLocaleString() : count.count?.toLocaleString()
                  } detections in selected filters`}
              </SubTitle>
            )}
          </FlexCol>
        </FlexCol>
      }
      secondContent={
        <DetectionVisualizer
          overviewBinSeconds={overviewBinSeconds}
          tableLimitReached={tableLimitReached}
          abacusEnabled={abacusEnabled}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          detectionState={detectionState}
          detectionRows={detectionRows}
          detectionCount={detCount}
          downloadDetections={downloadDetections}
          histogram={detectionHistogram}
          abacus={detectionAbacus}
          filterInterval={filterInterval}
          dataInterval={dataInterval}
          selectionInterval={selectionInterval}
          setSelectionInterval={setSelectionInterval}
          devicesInScope={devicesInScope}
          abacusGroupBy={abacusGroupBy}
          setAbacusGroupBy={setAbacusGroupBy}
        />
      }
    />
  );
}

export default Detections;
